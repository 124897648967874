import { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel"
import { Form, Button } from "react-bootstrap";
import { send } from "emailjs-com";

function App() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showEmailSent, setShowEmailSent] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();

    // Send form data to backend script for processing and email sending
    console.log("Submitting form...");
    console.log(`Name: ${name}`);
    console.log(`Email: ${email}`);
    console.log(`Subject: ${subject}`);
    console.log(`Message: ${message}`);

    send(
      "service_wholesalebulls",
      "template_support",
      {
        from_name: name,
        from_email: email,
        to_email: "info@wholesalebulls.com",
        subject: subject,
        message: message,
      },
      "jaByryc2Yt54xFF5s"
    )
    .then((response) => {
      console.log("SUCCESS!", response.status, response.text);
      setShowEmailSent(true);
      clearForm();
    })
    .catch((err) => {
      console.log("FAILED...", err);
    });
  }

  function clearForm() {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  }

  return (
    <>
      <header id="header">
        <div id="logo"><img src="logo.png" /></div>
        <div id="navigation">
          <a href="#carousel">Home</a>
          <a href="#store">Store</a>
          <a href="#aboutus">About Us</a>
          <a href="#contact">Contact</a>
        </div>
      </header>

      <section id="carousel">
        <Carousel>
          <Carousel.Item>
            <img className="d-block w-100" src="wholesale.png" />
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src="paying.png" />
            <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>

      <section id="store">
        <div className="item">
          <img src="logo192.png" />
          <div>
            <h6 className="itemTitle">Item 01</h6>
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi semper feugiat porta. Ut venenatis quis leo eget rhoncus. Sed lacus tortor, bibendum id facilisis ac, hendrerit ut velit. Proin lacinia mollis dolor vitae dapibus. Donec fermentum orci felis. Integer at mauris nec ex aliquam gravida.</span>
          </div>
        </div>
        
        <div className="item">
          <img src="logo192.png" />
          <div>
            <h6 className="itemTitle">Item 02</h6>
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi semper feugiat porta. Ut venenatis quis leo eget rhoncus. Sed lacus tortor, bibendum id facilisis ac, hendrerit ut velit. Proin lacinia mollis dolor vitae dapibus. Donec fermentum orci felis. Integer at mauris nec ex aliquam gravida.</span>
          </div>
        </div>

        <div className="item">
          <img src="logo192.png" />
          <div>
            <h6 className="itemTitle">Item 03</h6>
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi semper feugiat porta. Ut venenatis quis leo eget rhoncus. Sed lacus tortor, bibendum id facilisis ac, hendrerit ut velit. Proin lacinia mollis dolor vitae dapibus. Donec fermentum orci felis. Integer at mauris nec ex aliquam gravida.</span>
          </div>
        </div>

        <div className="item">
          <img src="logo192.png" />
          <div>
            <h6 className="itemTitle">Item 04</h6>
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi semper feugiat porta. Ut venenatis quis leo eget rhoncus. Sed lacus tortor, bibendum id facilisis ac, hendrerit ut velit. Proin lacinia mollis dolor vitae dapibus. Donec fermentum orci felis. Integer at mauris nec ex aliquam gravida.</span>
          </div>
        </div>
      </section>

      <section id="aboutus">
        <h2>About Us</h2>

        <p>Welcome to our online retail store! We are a new and exciting seller of high-quality products that meet the needs and preferences of our customers.</p>

        <p>As a new online retail seller, we are dedicated to providing exceptional customer service and building long-lasting relationships with our customers. We understand that shopping online can be challenging, and we strive to make the experience as seamless and enjoyable as possible. That"s why we have a team of knowledgeable professionals who are always available to answer any questions you may have and provide assistance throughout the purchasing process.</p>

        <p>Our store is committed to offering a wide range of products that meet the evolving needs and preferences of our customers. We carefully curate our selection of products to ensure that they meet the highest standards of quality and performance. Whether you"re looking for the latest technology, fashion-forward clothing, or everyday household items, we have something for everyone.</p>

        <p>At our online retail store, we understand the importance of customer satisfaction. That"s why we offer a hassle-free return policy and are committed to addressing any concerns or issues that may arise. We believe that building trust and loyalty with our customers is essential to our success, and we are dedicated to earning your business.</p>

        <p>Thank you for considering our online retail store as your go-to destination for high-quality products and exceptional customer service. We look forward to serving you and building a long-lasting relationship with you.</p>
      </section>

      <section id="contact">
        <h2>Contact</h2>
        
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name" className="formGroup">
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="email" className="formGroup">
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="subject" className="formGroup">
            <Form.Label>Subject:</Form.Label>
            <Form.Control
              type="text"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="message" className="formGroup">
            <Form.Label>Message:</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              required
            />
          </Form.Group>

          {showEmailSent && <span id="emailsent">Email has been sent successfully!</span>}
          <Button type="submit">Submit</Button>
        </Form>
      </section>
    </>
  );
}

export default App;
